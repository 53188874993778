import { DefaultLayoutProps } from "src/types/layoutTypes"
import DefaultFooter from "./DefaultFooter/DefaultFooter"
import MainHeader from "src/views/components/common/Header/MainHeader"
import { cx } from "class-variance-authority"

const IndexLayout = ({ children, hideFooter, type }: DefaultLayoutProps & { hideFooter?: boolean; type?: string }) => {
  return (
    <main className={cx(`flex min-h-screen flex-col`, type ? "bg-white md:bg-[#FBFBFB]" : "bg-[#FBFBFB]")}>
      <MainHeader />
      <div className="container mx-auto px-0 md:px-4 desktop:px-[50px] xl:px-[100px] min-h-screen">{children}</div>
      {!hideFooter && <DefaultFooter />}
    </main>
  )
}
export default IndexLayout
